import { createRouter, createWebHistory } from "vue-router";
import { validateToken } from "@/utills/validation";
import { findPermission } from "@/DIrectives/Access";
import { userStore } from "@/stores/store";
const user = userStore.activeUser;

const routes = [
  {
    path: "/",
    component: () => import("../layout/layout_page.vue"),
    beforeEnter: (to, from, next) => {
      if (validateToken(localStorage.getItem("token")) == false)
        next({ name: "Login_Page" });
      else next();
    },
    children: [
      {
        path: "",
        name: "AllDashboard",
        component: () =>
          import("../components/DashboardManagement/AllDashboard_Page.vue"),
      },
      // {
      //   path: "AllDashboard",
      //   name: "AllDashboard",
      //   component: () =>
      //     import("../components/DashboardManagement/AllDashboard_Page.vue"),
      // },
      {
        path: "Users",
        name: "Users",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "SETTINGS") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () => import("../components/Auth/Users_Page.vue"),
      },
      {
        path: "Setting",
        name: "Setting",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "SETTINGS") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        // component: () => import("../components/Auth/Setting_page.vue"),
        component: () => import("../views/SettingsListVIew.vue"),
      },
      {
        path: "vat-Settings",
        name: "VatRateSetting",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "VAT_MANAGEMENT") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () => import("../views/VatManageView.vue"),
      },
      {
        path: "Organisation-details",
        name: "Organisation-details",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "SETTINGS") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () => import("../components/Auth/Organisation_details.vue"),
      },
      {
        path: "Timesheets",
        name: "Timesheets_Page",
        beforeEnter: (to, from, next) => {
          const subscriptionType = localStorage.getItem("subscription_type");
          if (
            findPermission(user?.organization_role, null, "PAYROLL") == false ||
            subscriptionType === "SOLE"
          )
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/PayrollSettings/Timesheets/Timesheetslist_Page.vue"
          ),
      },
      {
        path: "PayrollOverview",
        name: "PayrollOverview_Page",
        beforeEnter: (to, from, next) => {
          const subscriptionType = localStorage.getItem("subscription_type");
          if (
            findPermission(user?.organization_role, null, "PAYROLL") == false ||
            subscriptionType === "SOLE"
          )
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/PayrollSettings/PayrollOverview_Page.vue"),
      },
      {
        path: "TimesheetsCreate/:id",
        name: "TimesheetsCreate_Page",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, null, "PAYROLL") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/PayrollSettings/Timesheets/TimesheetsCreate_Page"
          ),
      },
      {
        path: "PayEmployees",
        name: "PayEmployees_Page",
        beforeEnter: (to, from, next) => {
          const subscriptionType = localStorage.getItem("subscription_type");
          if (
            findPermission(user?.organization_role, null, "PAYROLL") == false ||
            subscriptionType === "SOLE"
          )
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/PayrollSettings/PayEmployees/PayEmployees_Page.vue"
          ),
      },
      {
        path: "Draft/:id",
        name: "Draft_Page",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, null, "PAYROLL") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/PayrollSettings/PayEmployees/Draft_Page.vue"),
      },
      {
        path: "Payslip/:id",
        name: "Payslip_Page",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, null, "PAYROLL") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/PayrollSettings/PayEmployees/Payslip_Page.vue"),
      },
      {
        path: "Leavelist",
        name: "Leavelist_Page",
        beforeEnter: (to, from, next) => {
          const subscriptionType = localStorage.getItem("subscription_type");
          if (
            findPermission(user?.organization_role, null, "PAYROLL") == false ||
            subscriptionType === "SOLE"
          )
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/PayrollSettings/Leave/Leavelist_Page.vue"),
      },
      {
        path: "ConnectedApps",
        name: "ConnectedApps",
        component: () => import("../components/Auth/ConnectedApps_page.vue"),
      },
      {
        path: "Currencies",
        name: "Currencies",
        component: () => import("../components/Auth/Currencies_Page.vue"),
      },
      {
        path: "Custom-Contact",
        name: "Custom-Contact",
        component: () => import("../components/Auth/CustomContact_Page.vue"),
      },
      {
        path: "Email-Settings",
        name: "Email-Settings",
        component: () => import("../components/Auth/EmailSettings_Page.vue"),
      },

      {
        path: "Employee",
        name: "Employee",
        beforeEnter: (to, from, next) => {
          const subscriptionType = localStorage.getItem("subscription_type");
          if (
            findPermission(user?.organization_role, null, "PAYROLL") == false ||
            subscriptionType === "SOLE"
          )
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/EmployeeManagement/Employee_Page.vue"),
      },
      {
        path: "Payrollsetting",
        name: "Payrollsetting",
        beforeEnter: (to, from, next) => {
          const subscriptionType = localStorage.getItem("subscription_type");
          if (
            findPermission(user?.organization_role, null, "PAYROLL") == false ||
            subscriptionType === "SOLE"
          )
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/PayrollSettings/Payrollsetting_Page.vue"),
      },
      {
        path: "Files",
        name: "Files",
        component: () => import("../components/Auth/Files_Page.vue"),
      },
      {
        path: "Fledger",
        name: "Fledger",
        component: () => import("../components/Auth/Fledger_Page.vue"),
      },
      {
        path: "Subscription",
        name: "Subscription",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "SUBS") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () => import("../components/Auth/Subscription_Page.vue"),
      },
      {
        path: "SubscriptionsCompany",
        name: "SubscriptionsCompany",
        component: () =>
          import("../components/Auth/SubscriptionsComp_Page.vue"),
      },
      {
        path: "Payment-Services",
        name: "Payment-Services",
        component: () => import("../components/Auth/Payment_Services.vue"),
      },
      {
        path: "TransferRequest",
        name: "TransferRequest",
        component: () => import("../components/Auth/TransferRequest_Page.vue"),
      },
      {
        path: "Account-detail",
        name: "Account-detail",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/BankAccountManagement/AccountDetail_Page.vue"),
      },
      {
        path: "Add-Account",
        name: "Add-Account",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/BankAccountManagement/AddAccount_Page.vue"),
      },
      {
        path: "Advanced",
        name: "Advanced",
        component: () =>
          import("../components/BankAccountManagement/Advanced_page.vue"),
      },
      {
        path: "Bank-Account",
        name: "Bank-Account",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "BANK") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/BankAccountManagement/BankAccount_Page.vue"),
      },
      {
        path: "Bank-Statement-Detail",
        name: "Bank-Statement-Detail",
        component: () =>
          import(
            "../components/BankAccountManagement/BankStatementDetail_Page.vue"
          ),
      },
      {
        path: "Account-Transaction-Detail/:id",
        name: "Account-Transaction-Detail",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "BANK") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/AccountTransactionDetail_Page.vue"
          ),
      },
      {
        path: "Chart-of-accounts",
        name: "Chart-of-accounts",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "ACCOUNT") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/ChartOfAccounts_Page.vue"
          ),
      },
      {
        path: "Conversion-balances",
        name: "Conversion-balances",
        component: () =>
          import(
            "../components/BankAccountManagement/ConversionBalance_Page.vue"
          ),
      },
      {
        path: "Financial-settings",
        name: "Financial-settings",
        component: () =>
          import(
            "../components/BankAccountManagement/FinancialSettings_Page.vue"
          ),
      },
      {
        path: "Fixed-assets",
        name: "Fixed-assets",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "ASSET") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/BankAccountManagement/FixedAssets_Page.vue"),
      },
      {
        path: "SummaryValue_Page:id",
        name: "SummaryValue",
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/SummaryValue_Page.vue"
          ),
      },
      {
        path: "NewTransaction/:id",
        name: "NewTransaction",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/NewTransaction_Page.vue"
          ),
      },
      {
        path: "CreateRule",
        name: "CreateRule",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/CreateRule_Page.vue"
          ),
      },
      {
        path: "Bankstatementsdetails",
        name: "Bankstatementsdetails",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/Bankstatementsdetails_Page.vue"
          ),
      },
      {
        path: "ViewStatement",
        name: "ViewStatement",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/ViewStatement_Page.vue"
          ),
      },
      {
        path: "Transaction_Payment",
        name: "Transaction_Payment",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/Transaction_Payment_Page.vue"
          ),
      },
      {
        path: "BankReconciliation",
        name: "BankReconciliation",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/BankReconciliation_Page.vue"
          ),
      },
      {
        path: "Bankrules",
        name: "Bankrules",
        component: () =>
          import(
            "../components/BankAccountManagement/Bank_Accounts/Bankrules_Page.vue"
          ),
      },
      {
        path: "NewAsset",
        name: "NewAsset_Page",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/AddNewAsset_Page.vue"
          ),
      },

      {
        path: "NewAssetEdit/:id",
        name: "NewAssetEdit",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/AddNewAsset_Page.vue"
          ),
      },
      {
        path: "RunDepreciation",
        name: "RunDepreciation",
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/RunDepreciation_Page.vue"
          ),
      },
      {
        path: "Import",
        name: "Import",
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/Import_Page.vue"
          ),
      },
      {
        path: "Settings",
        name: "Settings",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "ASSET") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/Settings_Page.vue"
          ),
      },
      {
        path: "AssetTypeNew",
        name: "AssetTypeNew",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/AssetTypeNew.vue"
          ),
      },
      {
        path: "AssetTypeNewEdit/:id",
        name: "AssetTypeNewEdit",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/FixedAssets/AssetTypeNew.vue"
          ),
      },
      {
        path: "BusinessBankAccount/:id",
        name: "BusinessBankAccount",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "BANK") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import(
            "../components/BankAccountManagement/BusinessBankAccount_Page.vue"
          ),
      },

      {
        path: "Import-Chart-Of-Accounts",
        name: "Import-Chart-Of-Accounts",
        component: () =>
          import("../components/BankAccountManagement/ImportChartAcc_Page.vue"),
      },
      {
        path: "Report",
        name: "Report",
        component: () =>
          import("../components/BankAccountManagement/Reports/Report_Page.vue"),
      },
      {
        path: "DraftPage",
        name: "DraftPage",
        component: () =>
          import("../components/BankAccountManagement/Reports/DraftPage.vue"),
      },
      {
        path: "Sales-Tax-Report",
        name: "Sales-Tax-Report",
        component: () =>
          import("../components/BankAccountManagement/SalesTaxReport_Page.vue"),
      },
      {
        path: "Tax-rates",
        name: "Tax-rates",
        component: () =>
          import("../components/BankAccountManagement/Taxrates_Page.vue"),
      },
      {
        path: "Tracking-categories",
        name: "Tracking-categories",
        component: () =>
          import(
            "../components/BankAccountManagement/TrackingCategories_Page.vue"
          ),
      },

      // Business Management
      {
        path: "Billpay",
        name: "Billpay",
        component: () =>
          import("../components/BusinessManagement/Billpay_Page.vue"),
      },
      {
        path: "MakePayment",
        name: "MakePayment",
        component: () =>
          import("../components/BusinessManagement/MakePayment.vue"),
      },
      {
        path: "ViewTransaction/:id",
        name: "ViewTransaction",
        component: () =>
          import("../components/BusinessManagement/ViewTransaction.vue"),
      },
      {
        path: "Business-Snapshot",
        name: "Business-Snapshot",
        component: () =>
          import("../components/BusinessManagement/BusinessSnapshot_Page.vue"),
      },
      // Transactions
      {
        path: "Account-Transactions/:id?",
        name: "Account-Transactions",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Transactions/AccountTransactions_Page.vue"
          ),
      },
      {
        path: "InventoryItemDetails/:id?",
        name: "InventoryItemDetails",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Transactions/InventoryItemDetails_Page.vue"
          ),
      },
      {
        path: "InventoryItemSummary/:id?",
        name: "InventoryItemSummary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Transactions/InventoryItemSummary_Page.vue"
          ),
      },
      {
        path: "Sales-by-Item",
        name: "Sales-by-Item",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Transactions/SalesbyItem_Page.vue"
          ),
      },
      // Payroll
      {
        path: "Employee-Contact-Details",
        name: "Employee-Contact-Details",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payroll/EmployeeContactDetails_Page.vue"
          ),
      },
      {
        path: "Gross-To-Net",
        name: "Gross-To-Net",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payroll/GrossToNet_Page.vue"
          ),
      },
      {
        path: "Payroll-Activity-Details",
        name: "Payroll-Activity-Details",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payroll/PayrollActivityDetails_Page.vue"
          ),
      },
      {
        path: "Payroll-Activity-Summary",
        name: "Payroll-Activity-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payroll/PayrollActivitySummary_Page.vue"
          ),
      },
      {
        path: "Transaction-Listing-Detail",
        name: "Transaction-Listing-Detail",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payroll/TransactionDetail_Page.vue"
          ),
      },
      {
        path: "Transaction-Listing-Summary",
        name: "Transaction-Listing-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payroll/TransactionListingSummary_Page.vue"
          ),
      },
      // Reconciliations
      {
        path: "Account-Summary/:id?",
        name: "Account-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Reconciliations/AccountSummary.vue"
          ),
      },
      {
        path: "Bank-Reconciliation",
        name: "Bank-Reconciliation",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Reconciliations/BankReconciliation.vue"
          ),
      },
      {
        path: "Bank-Summary/:id?",
        name: "Bank-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Reconciliations/BankSummary.vue"
          ),
      },
      {
        path: "Inventory-Item-List",
        name: "Inventory-Item-List",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Reconciliations/InventoryItemList.vue"
          ),
      },
      {
        path: "Reconciliation-Reports",
        name: "Reconciliation-Reports",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Reconciliations/ReconciliationReports.vue"
          ),
      },
      {
        path: "Uncoded-Statement-Lines",
        name: "Uncoded-Statement-Lines",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Reconciliations/UncodedStatementLines.vue"
          ),
      },

      //  Payables and receivables
      {
        path: "Aged-Payables-Detail/:id?",
        name: "Aged-Payables-Detail",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/AgedPayablesDetail_Page.vue"
          ),
      },
      {
        path: "Aged-Payables-Summary/:id?",
        name: "Aged-Payables-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/AgedPayablesSummary_Page.vue"
          ),
      },
      {
        path: "Aged-Receivables-Detail/:id?",
        name: "Aged-Receivables-Detail",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/AgedReceivablesDetail_Page.vue"
          ),
      },
      {
        path: "Aged-Receivables-Summary/:id?",
        name: "Aged-Receivables-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/AgedReceivablesSummary_Page.vue"
          ),
      },
      {
        path: "Contact-Transactions-Summary",
        name: "Contact-Transactions-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/ContactTransactionsSummary_Page.vue"
          ),
      },
      {
        path: "Payable-Invoice-Detail",
        name: "Payable-Invoice-Detail",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/PayableInvoiceDetail_Page.vue"
          ),
      },
      {
        path: "Payable-Invoice-summary",
        name: "Payable-Invoice-summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/PayableInvoicesummary_Page.vue"
          ),
      },
      {
        path: "Receivable-Invoice-Detail",
        name: "Receivable-Invoice-Detail",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/ReceivableInvoiceDetail_Page.vue"
          ),
      },
      {
        path: "Receivable-Invoice-Summary",
        name: "Receivable-Invoice-Summary",
        component: () =>
          import(
            "../components/BankAccountManagement/Reports/Payables_receivables/ReceivableInvoiceSummary_Page.vue"
          ),
      },

      {
        path: "Expense-claims",
        name: "Expense-claims",
        component: () =>
          import("../components/BusinessManagement/ExpenseClaims_Page.vue"),
      },
      {
        path: "Import-bills",
        name: "Import-bills",
        component: () =>
          import("../components/BusinessManagement/Importbills_Page.vue"),
      },
      {
        path: "Import-Items",
        name: "Import-Items",
        component: () =>
          import("../components/BusinessManagement/ImportItems_Page.vue"),
      },
      {
        path: "NewBill/:id?",
        name: "NewBill",
        component: () =>
          import("../components/BusinessManagement/NewBill_Page.vue"),
      },
      {
        path: "New-Quotes",
        name: "New-Quotes",
        component: () =>
          import("../components/BusinessManagement/NewQuotes_Page.vue"),
      },
      {
        path: "Opening-Balances",
        name: "Opening-Balances",
        component: () =>
          import("../components/BusinessManagement/OpeningBalances_Page.vue"),
      },
      {
        path: "Pay-Run",
        name: "Pay-Run",
        component: () =>
          import("../components/BusinessManagement/PayRun_Page.vue"),
      },
      {
        path: "TaxesFilings",
        name: "TaxesFilings_Page",
        component: () =>
          import("../components/PayrollSettings/TaxesFilings_Page.vue"),
      },
      {
        path: "Products-services",
        name: "Products-services",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "PRODUCT") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/BusinessManagement/ProductsServices_Page.vue"),
      },
      {
        path: "Products-history",
        name: "Products-history",
        component: () =>
          import("../components/BusinessManagement/Productshistory_Page.vue"),
      },
      {
        path: "Purchase-Orders",
        name: "Purchase-Orders",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "PO") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/BusinessManagement/PurchaseOrders_Page.vue"),
      },
      {
        path: "New_Purchase_Order",
        name: "New_Purchase_Order",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/InvoiceManagement/New_Purchase_Order.vue"),
      },
      {
        path: "Purchase_Order_view/:id",
        name: "Purchase_Order_view",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/InvoiceManagement/Purchase_Order_view.vue"),
      },
      {
        path: "New_Purchase_Order_Edit/:id",
        name: "New_Purchase_Order_Edit",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/InvoiceManagement/New_Purchase_Order.vue"),
      },
      {
        path: "Purchases-Overview",
        name: "Purchases-Overview",
        component: () =>
          import("../components/BusinessManagement/PurchasesOverview_Page.vue"),
      },
      {
        path: "Quotes",
        name: "Quotes",
        component: () =>
          import("../components/BusinessManagement/Quotes_Page.vue"),
      },
      {
        path: "Sales-Overview",
        name: "Sales-Overview",
        component: () =>
          import("../components/BusinessManagement/SalesOverview_Page.vue"),
      },
      {
        path: "Setup-Expense",
        name: "Setup-Expense",
        component: () =>
          import("../components/BusinessManagement/SetupExpense_Page.vue"),
      },
      {
        path: "Short-Term",
        name: "Short-Term",
        component: () =>
          import("../components/BusinessManagement/ShortTerm_Page.vue"),
      },

      // Customer management
      {
        path: "Contact",
        name: "Contact",
        component: () =>
          import("../components/CustomerManagement/Contact_page.vue"),
      },
      {
        path: "Contact-Details",
        name: "Contact-Details",
        component: () =>
          import("../components/CustomerManagement/ContactDetails_Page.vue"),
      },
      {
        path: "Contact_D_Page/:id",
        name: "Contact_D_Page",
        component: () =>
          import("../components/CustomerManagement/Contact_D_Page.vue"),
      },
      {
        path: "Contact-Edit/:id",
        name: "Contact-Edit",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/CustomerManagement/ContactDetails_Page.vue"),
      },
      {
        path: "Customer",
        name: "Customer",
        component: () =>
          import("../components/CustomerManagement/Customer_page.vue"),
      },
      {
        path: "Suppliers",
        name: "Suppliers",
        component: () =>
          import("../components/CustomerManagement/Suppliers_Page.vue"),
      },
      // Employee management
      {
        path: "EditEmployee/:id",
        name: "EditEmployee",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, null, "PAYROLL") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/EmployeeManagement/EditEmployee_Page.vue"),
      },
      {
        path: "Employee-Report",
        name: "Employee-Report",
        component: () =>
          import("../components/EmployeeManagement/EmployeeReoprt_Page.vue"),
      },

      // Invoice Management
      {
        path: "Invoice-Import",
        name: "/Invoice_Import",
        component: () =>
          import("../components/InvoiceManagement/InvoiceImport_Page.vue"),
      },
      {
        path: "Invoice-Management",
        name: "Invoice-Management",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "INV") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/InvoiceManagement/InvoiceManage_Page.vue"),
      },
      {
        path: "Invoice-Settings",
        name: "Invoice-Settings",
        component: () =>
          import("../components/InvoiceManagement/InvoceSettingsUpdate_page.vue"),
      },
      {
        path: "Invoice-Statement",
        name: "Invoice-Statement",
        component: () =>
          import("../components/InvoiceManagement/InvoiceStatements_Page.vue"),
      },
      {
        path: "Invoice-Reminder",
        name: "Invoice-Reminder",
        component: () =>
          import("../components/InvoiceManagement/InvoiceReminder_Page.vue"),
      },
      {
        path: "NewCreditNote",
        name: "/NewCreditNote",
        component: () =>
          import("../components/InvoiceManagement/NewCreditNote_Page.vue"),
      },
      {
        path: "New-Invoice",
        name: "/New-Invoice",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/InvoiceManagement/NewInvoice_Page.vue"),
      },
      {
        path: "New-Deposit/:id",
        name: "/New-Deposit",
        component: () =>
          import("../components/InvoiceManagement/NewDeposit_Page.vue"),
      },
      {
        path: "Batch-Deposit/:id",
        name: "/Batch-Deposit",
        component: () =>
          import("../components/InvoiceManagement/BatchDeposit_Page.vue"),
      },
      {
        path: "New-Invoice-Edit/:id",
        name: "/New-Invoice-Edit",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "CREATE") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/InvoiceManagement/NewInvoice_Page.vue"),
      },
      {
        path: "NewInvoiceDraft",
        name: "NewInvoiceDraft",
        component: () =>
          import("../components/InvoiceManagement/NewInvoiceDraft_page.vue"),
      },
      // User Account
      {
        path: "Edit-Profile",
        name: "Edit-Profile",
        component: () =>
          import("../components/UserAccount/EditProfile_Page.vue"),
      },
      {
        path: "Invite-User",
        name: "Invite-User",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "SETTINGS") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/UserAccount/InviteUser_Page.vue"),
      },
      {
        path: "User_Account",
        name: "UserAccount_Page",
        component: () =>
          import("../components/UserAccount/UserAccount_Page.vue"),
      },
      {
        path: "NewRepeatInvoice_Page",
        name: "NewRepeatInvoice_Page",
        component: () =>
          import("../components/InvoiceManagement/NewRepeatInvoice_page.vue"),
      },
      {
        path: "Updatepermissions/:id",
        name: "Updatepermissions",
        beforeEnter: (to, from, next) => {
          if (findPermission(user?.organization_role, "SETTINGS") == false)
            next({ name: "PagenotFound" });
          else next();
        },
        component: () =>
          import("../components/UserAccount/Updatepermissions_Page.vue"),
      },

      // Dashboard Management

      {
        path: "ChooseReports",
        name: "ChooseReports",
        component: () =>
          import("../components/DashboardManagement/ChooseReports_Page.vue"),
      },
      {
        path: "CreateWatchlist",
        name: "CreateWatchlist",
        component: () =>
          import("../components/DashboardManagement/CreateWatchlist_Page.vue"),
      },
      {
        path: "NewProject",
        name: "NewProject",
        component: () =>
          import("../components/DashboardManagement/NewProject_Page.vue"),
      },
      {
        path: "Progress-bar",
        name: "Progress bar",
        component: () =>
          import("../components/DashboardManagement/Progressbar_Page.vue"),
      },
      {
        path: "Projects-Settings",
        name: "Projects-Settings",
        component: () =>
          import("../components/DashboardManagement/ProjectsSettings_Page.vue"),
      },
    ],
  },
  {
    path: "/Email-Verify/:status/:id",
    name: "Email-Verify",
    component: () =>
      import("../components/Auth/PaymentServices/EmailVerify_Page.vue"),
  },

  // Auth
  {
    path: "/Confirmation",
    name: "Confirmation",
    component: () => import("../components/Auth/Confirmation_Page.vue"),
  },
  {
    path: "/Verify-Email",
    name: "VerifyUser_Page",
    component: () => import("../components/Auth/VerifyUser_Page.vue"),
  },

  {
    path: "/Forget",
    name: "Forget",
    component: () => import("../components/Auth/Forget_Page.vue"),
  },
  {
    path: "/login",
    name: "Login_Page",
    component: () => import("../components/Auth/Login_Page.vue"),
  },
  {
    path: "/ResetPassLink",
    name: "ResetPassLink",
    component: () => import("../components/Auth/ResetPassLink_Page.vue"),
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../components/Auth/Resetpassword_Page.vue"),
  },
  {
    path: "/SignUp",
    name: "SignUp",
    component: () => import("../components/Auth/SignUp_Page.vue"),
  },

  {
    path: "/OtpVerification",
    name: "OtpVerification",
    component: () => import("../components/Auth/OtpVerification_Page.vue"),
  },
  {
    path: "/:pathmatch(.*)*",
    name: "PagenotFound",
    component: () => import("../components/Auth/NotFound_Page.vue"),
  },
];
// const router = createRouter({
//   history: createWebHistory(window.location.pathname),
//   routes,
// });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
